<template>
  <div class="mb-32 md:mb-56">
    <CMSContext v-if="context" v-bind="context" :cms-page-id="content?.id" />

    <div ref="articleContent">
      <ArticleBannerImage
        v-if="bannerImage"
        :banner-image
        :breadcrumb-text-color="content?.banner?.textColor"
        :current-page-title="breadcrumb.currentPageTitle"
        :display-breadcrumb="!isWebview"
        :intermediary-path="breadcrumb.intermediaryPath"
        :tracking="trackBreadcrumbClicked"
      />

      <Container
        :class="[
          bannerImage ? 'pt-12 md:pt-32' : 'bg-static-info-max mb-32 md:mb-40',
        ]"
        data-test="article-default-banner"
        width="full-width"
      >
        <Breadcrumb
          v-if="!bannerImage && !isWebview"
          :current-page-title="breadcrumb.currentPageTitle"
          data-test="article-breadcrumb"
          :intermediary-path="breadcrumb.intermediaryPath"
          @breadcrumb-clicked="(title: string) => trackBreadcrumbClicked(title)"
        />

        <div
          v-if="content"
          class="m-auto flex flex-col items-start px-24 pb-20 md:max-w-[736px] md:px-0"
          :class="{ 'pt-20': !bannerImage && isWebview }"
        >
          <RevTag
            v-if="content.badge"
            class="mb-12"
            :label="content.badge"
            variant="alternate"
          />

          <Heading
            class="font-weight-heading-2 text-start"
            data-test="article-heading"
            :title="content.title"
          />

          <ArticleInfo class="mt-2 flex md:hidden" :content />

          <div class="mt-20 flex" :class="{ 'md:mt-24': !bannerImage }">
            <RevIllustration
              v-if="author"
              :alt="author.image?.alt"
              class="rounded-full mr-8 self-start object-cover md:mt-0 md:self-center"
              :height="40"
              image-class-names="h-40 w-40"
              :src="author.image?.src"
              :width="40"
            />

            <div class="flex flex-col">
              <div v-if="author">
                <div
                  class="body-2-bold block text-start md:body-1-bold md:hidden"
                >
                  <p>{{ author.name }}</p>
                  <p v-if="author.role">{{ author.role }}</p>
                </div>

                <p class="body-1-bold hidden text-start md:block">
                  {{ bannerAuthorInfo }}
                </p>
              </div>

              <ArticleInfo class="hidden md:flex" :content />
            </div>
          </div>
        </div>
      </Container>

      <div class="min-h-[100vh]">
        <div v-if="content">
          <ArticleEditorial :summary="content.summary" />

          <TableOfContent
            v-if="content.showTableOfContent && content.blocks"
            :blocks="content.blocks"
            :show-toggle="isArticleVisible"
          />

          <ArticleBlockContainer
            v-for="(
              { id, props, hiddenDevices, type }, index
            ) in content.blocks"
            :key="id"
            :block-type="type"
          >
            <BlockRenderer
              :id
              :block-position="index + 1"
              :data-test="id"
              :hidden-devices
              :props
              :type
            />
          </ArticleBlockContainer>
        </div>
      </div>

      <Container v-if="author" ref="authorBlock">
        <Author v-bind="author" :tracking="authorTrackingData">
          <VoteButtons v-bind="votes" />
        </Author>
      </Container>
    </div>

    <Container v-if="relatedArticles.length > 0">
      <div class="my-16 pt-56" data-test="related-articles">
        <ArticleCardsCollection
          :articles="relatedArticles"
          layout="carousel"
          :title="relatedArticlesTitle"
        />
      </div>
    </Container>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRuntimeConfig } from '#imports'
import { computed, ref, watch } from 'vue'

import ArticleCardsCollection from '@backmarket/nuxt-layer-cms/ArticleCardsCollection.vue'
import Author from '@backmarket/nuxt-layer-cms/Author.vue'
import CMSContext from '@backmarket/nuxt-layer-cms/CMSContext.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevIllustration } from '@ds/components/Illustration'
import { RevTag } from '@ds/components/Tag'
import { useElementVisibility } from '@vueuse/core'

import { useFetchPageContext } from '../../cms/composables/useFetchPageContext'
import { buildTrackingZone } from '../../cms/helpers/tracking/tracking'
import BlockRenderer from '../../cms/shared-components/BlockRenderer/BlockRenderer.vue'
import Breadcrumb from '../../cms/shared-components/Breadcrumb/Breadcrumb.vue'
import Container from '../../cms/shared-components/Container.vue'
import Heading from '../../cms/shared-components/Heading/Heading.vue'

import translations from './Article.translations'
import ArticleBannerImage from './components/ArticleBannerImage.vue'
import ArticleBlockContainer from './components/ArticleBlockContainer/ArticleBlockContainer.vue'
import ArticleEditorial from './components/ArticleEditorial.vue'
import ArticleInfo from './components/ArticleInfo.vue'
import TableOfContent from './components/TableOfContents/TableOfContent.vue'
import VoteButtons from './components/VoteButtons/VoteButtons.vue'
import { useArticleHead } from './composables/useArticleHead'
import { useFetchArticle } from './composables/useFetchArticle'
import { useGetBreadcrumb } from './composables/useGetBreadcrumb'
import { useGetRelatedArticles } from './composables/useGetRelatedArticles'
import { useHandleArticleFetchErrors } from './composables/useHandleFetchErrors'
import { useHandleIncorrectRouteParams } from './composables/useHandleIncorrectRouteParams'

const route = useRoute()
const tracking = useTracking()
const i18n = useI18n()

const { FF_CMS_CONTEXT } = useRuntimeConfig().public

const { fetchedContent, fetchedVotes } = await useFetchArticle()

const { data: content, error: contentErrorRef } = fetchedContent

const { data: votes, error: votesErrorRef } = fetchedVotes
useHandleArticleFetchErrors({ contentErrorRef, votesErrorRef })
useHandleIncorrectRouteParams(content)
useArticleHead(content)

const author = computed(() => content.value?.author?.props)
const relatedArticles = useGetRelatedArticles(content)

const breadcrumb = useGetBreadcrumb(content)

const articleContent = ref<HTMLElement>()
const authorBlock = ref<HTMLElement>()

const articleObserver = useElementVisibility(articleContent)
const authorBlockObserver = useElementVisibility(authorBlock)

const isArticleVisible = ref<boolean>(true)

const context = useFetchPageContext({
  showCmsContext: FF_CMS_CONTEXT,
  content: fetchedContent.data.value,
})

const isWebview = route.meta?.isWebview || false
const bannerImage = computed(() => content.value?.banner?.image)

const bannerAuthorInfo = computed(() => {
  if (!author.value) return ''

  let authorInfo = author.value.name
  if (author.value.role) authorInfo += `, ${author.value.role}`

  return authorInfo
})

// TODO COM-1345 Keep translation only
const relatedArticlesTitle = computed(
  () => content.value?.relatedArticles?.props.title ?? i18n(translations.title),
)

const trackingZone = buildTrackingZone([
  <string>route.name,
  route.params.pageName,
])

const authorTrackingData = computed(() => {
  const categories = content?.value?.categories || []

  if (!(categories?.length > 0)) {
    return { zone: trackingZone, articleCategory: '' }
  }

  return {
    zone: trackingZone,
    articleCategory: categories[categories.length - 1].name || '',
  }
})

function trackBreadcrumbClicked(breadcrumbTitle: string) {
  tracking.trackClick({
    name: breadcrumbTitle,
    value: 'Breadcrumb',
    zone: trackingZone,
  })
}

watch([articleObserver, authorBlockObserver], () => {
  isArticleVisible.value = articleObserver.value && !authorBlockObserver.value
})
</script>
